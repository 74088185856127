import React from 'react'
import ResourceItem from './ResourceItem'
import { v4 as uuidv4 } from 'uuid'


const ResourceList = ({ todos, dispatch, setTag, setActive, inputRef }) => (
  <>
    {todos.map(todo => (
      <ResourceItem
        key={uuidv4()}
        resource={todo}
        dispatch={dispatch}
        setTag={setTag}
        setActive={setActive}
        inputRef={inputRef} />
    ))}
  </>
);


export default ResourceList
