import React, { useRef } from 'react'


const ResourceFilter = ({ dispatch, setActive, active, currentTag, setTag, setSearch, filterSearch, inputRef }) => {
  const handleShowAll = () => {
    dispatch({ type: 'SHOW_ALL' });
    setActive(4);
    setTag(null);
    inputRef.current.value = '';
  };
  const handleShowArticles = () => {
    dispatch({ type: 'SHOW_ARTICLES' });
    setActive(0);
    setTag(null);
    inputRef.current.value = '';
  };

  const handleShowBooks = () => {
    dispatch({ type: 'SHOW_BOOKS' });
    setActive(1);
    setTag(null);
    inputRef.current.value = '';
  };

  const handleShowVideos = () => {
    dispatch({ type: 'SHOW_VIDEOS' });
    setActive(2);
    setTag(null);
    inputRef.current.value = '';
  };
  const handleShowPodcasts = () => {
    dispatch({ type: 'SHOW_PODCASTS' });
    setActive(3);
    setTag(null);
    inputRef.current.value = '';
  };
  const handleSearch = (e) => {
    dispatch({ type: 'SHOW_SEARCH' });
    setActive(4);
    setTag(null);
    setSearch(e.target.value);
  };



  return (
    <section className="full-width-container">
      <nav
        className="navbar is-transparent"
        role="navigation"
        aria-label="page-navigation"
      >
        <div
          id="navMenu"
          className="resource-menu"
        >
          <div className="navbar-start has-text-centered px-5 py-3">
            <button
              className={`button link normal p-4 ${active === 0 ? 'is-active' : null}`} onClick={handleShowArticles}
            >
              ARTICLES
              </button>
            <button
              className={`button link normal p-4 ${active === 1 ? 'is-active' : null}`} onClick={handleShowBooks}
            >
              BOOKS
              </button>
            <button
              className={`button link normal p-4 ${active === 2 ? 'is-active' : null}`} onClick={handleShowVideos}
            >
              VIDEOS
              </button>
            <button
              className={`button link normal p-4 ${active === 3 ? 'is-active' : null}`} onClick={handleShowPodcasts}
            >
              PODCASTS
              </button>
            {currentTag === null ? null :
              <div className="my-4 ml-5 tag is-primary">
                {currentTag.toUpperCase()}
                <button class="delete is-small" onClick={handleShowAll}></button>
              </div>
            }
          </div>
        </div>
        <div className="navbar-end is-flex is-align-items-center is-justify-content-center px-3">
          <input
            ref={inputRef}
            id="searchField"
            type="text"
            placeholder="WHAT ARE YOU LOOKING FOR?"
            className="input px-3 has-text-primary has-text-weight-bold is-uppercase"
            onChange={handleSearch}
            style={{ minWidth: '270px', maxWidth: '400px', height: '32px', borderRadius: '0' }}
          />
          {inputRef.current.value === '' || filterSearch === null ?
            <span className="tag search is-primary is-medium"></span> :
            <button className="button tag is-white is-delete is-medium" onClick={handleShowAll}></button>}
        </div>
      </nav>
    </section>
  );

}
export default ResourceFilter;
