import React from 'react'
import ScrollAnimation from 'react-animate-on-scroll'
import "animate.css/animate.min.css"


const ResourceItem = ({ resource, dispatch, setTag, setActive, inputRef }) => {
  const handleShowTags = (e) => {
    dispatch({ type: 'SHOW_TAGS' });
    setTag(e.target.value);
    setActive(4);
    inputRef.current.value = '';
  };

  return (
    <>
      <ScrollAnimation animateIn="animate__fadeIn">
        <div className="resource-links has-text-centered py-6">
          <h6 className="has-text-weight-normal"><a href={resource.url} className="external-link" target="_blank" rel="noreferrer noopener">{resource.title}</a></h6>
          <h6 className="smallest is-uppercase">
            {resource.date} &bull;
            {resource.tags.map((item) => (
              <button key={item}
                value={item}
                className="button link normal is-active is-underline is-uppercase pl-3"
                style={{ fontSize: 'inherit' }}
                onClick={handleShowTags}
              >
                {item}
              </button>
            ))}
          </h6>
        </div>
      </ScrollAnimation>
    </ >
  )
}


export default ResourceItem
