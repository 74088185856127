import React, {
  useState,
  useReducer,
  createContext,
  useRef,
} from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import Layout from '../components/Layout'
import ResourceList from '../components/resources/ResourceList'
import ResourceFilter from '../components/resources/ResourceFilter'

const TodoContext = createContext(null);

const filterReducer = (state, action) => {
  switch (action.type) {
    case 'SHOW_ALL':
      return 'ALL';
    case 'SHOW_ARTICLES':
      return 'ARTICLES';
    case 'SHOW_BOOKS':
      return 'BOOKS';
    case 'SHOW_VIDEOS':
      return 'VIDEOS';
    case 'SHOW_PODCASTS':
      return 'PODCASTS';
    case 'SHOW_TAGS':
      return 'TAGS';
    case 'SHOW_SEARCH':
      return 'SEARCH';
    default:
      throw new Error();
  }
};

export const ResourcesPageTemplate = ({ fullImage, resourceLinks }) => {

  const [filter, dispatchFilter] = useReducer(filterReducer, 'ARTICLES');
  const [filterTag, setTag] = useState(null);
  const [filterSearch, setSearch] = useState(null);
  const [active, setActive] = useState(0);
  const inputRef = useRef('');

  const filteredResources = resourceLinks.filter(todo => {

    if (filter === 'ALL') {
      return true;
    }

    if (filter === 'ARTICLES' && todo.type === "articles") {
      return true;
    }

    if (filter === 'BOOKS' && todo.type === "books") {
      return true;
    }

    if (filter === 'VIDEOS' && todo.type === "videos") {
      return true;
    }
    if (filter === 'PODCASTS' && todo.type === "podcasts") {
      return true;
    }
    if (filter === 'TAGS' && todo.tags.includes(filterTag.toUpperCase())) {
      return true;
    }
    if (filter === 'SEARCH' && todo.title.toLowerCase().includes(filterSearch.toLowerCase())) {
      return true;
    }
    return false;
  });


  return (
    <>
      <div
        className="full-width-image-container margin-top-0"
        style={{
          minHeight: '450px',
          height: '50vh',
          backgroundImage: `url(${!!fullImage.childImageSharp ? fullImage.childImageSharp.fluid.src : fullImage
            })`,
          backgroundSize: 'cover',
          backgroundPosition: 'center'
        }}
      >
      </div>
      <TodoContext.Provider value={dispatchFilter}>
        <ResourceFilter
          dispatch={dispatchFilter}
          setActive={setActive}
          active={active}
          currentTag={filterTag}
          setTag={setTag}
          setSearch={setSearch}
          filterSearch={filterSearch}
          inputRef={inputRef} />
        <ResourceList
          todos={filteredResources}
          dispatch={dispatchFilter}
          setTag={setTag}
          setActive={setActive}
          inputRef={inputRef} />
      </TodoContext.Provider>


    </>
  )
}




ResourcesPageTemplate.propTypes = {
  fullImage: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  resourceLinks: PropTypes.array,
}

const ResourcesPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark

  return (
    <Layout>
      <ResourcesPageTemplate
        fullImage={frontmatter.fullImage}
        resourceLinks={frontmatter.resourceLinks}
      />
    </Layout>
  )
}

ResourcesPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
}

export default ResourcesPage

export const ResourcesPageQuery = graphql`
  query ResourcesPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        fullImage {
          childImageSharp {
            fluid(maxWidth: 2048, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        resourceLinks {
          type
          title
          date
          tags
          url
        }
      }
    }
  }
`
